<template>
  <div class="application-create">
    <b-row>
      <b-col>
        <form-wizard
          color="#9432BB"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Создать"
          back-button-text="Назад"
          next-button-text="Далее"
          @on-complete="createApplication"
        >
          <tab-content title="Общая информация">
            <b-row>
              <b-col md="3" sm="6">
                <b-form-group label="Клиент">
                  <b-form-select
                    v-model="form.user"
                    :options="clients"
                    value-field="id"
                    text-field="full_name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" sm="6">
                <b-form-group label="Сумма">
                  <b-form-input
                    v-model="form.preferable_amount"
                    type="number"
                    placeholder="Сумма"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" sm="6">
                <b-form-group label="Срок">
                  <b-form-select
                    v-model="form.duration_months"
                    :options="[6, 12, 18, 24]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>
          <tab-content title="Информация об авто">
            <b-row>
              <b-col md="2" sm="3">
                <b-form-group label="Марка">
                  <b-form-select
                    v-model="selectedMark"
                    :options="carMarks"
                    value-field="id"
                    text-field="name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="3">
                <b-form-group label="Модель">
                  <b-form-select
                    v-model="form.car"
                    :options="carModels"
                    value-field="id"
                    text-field="name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="3">
                <b-form-group label="Год">
                  <b-form-input v-model="form.year" type="number" />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="3">
                <b-form-group label="Пробег">
                  <b-form-input v-model="form.mileage" type="number" />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="3">
                <b-form-group label="VIN">
                  <b-form-input v-model="form.vin" />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="3">
                <b-form-group label="Рег. номер">
                  <b-form-input
                    v-model="form.license_plate"
                    placeholder="0000-AA-1"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="3">
                <b-form-group label="Рыночная стоимость, BYN">
                  <b-form-input v-model="form.market_price" type="number" />
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>
          <tab-content title="Дополнительная информация">
            <b-row>
              <b-col md="4" sm="6">
                <b-form-group label="Комментарии">
                  <b-form-textarea v-model="form.comment" />
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'ApplicationCreate',
  components: {
    FormWizard,
    TabContent,
  },
  data() {
    return {
      form: {},
      clients: [],
      carMarks: [],
      carModels: [],
      selectedMark: null,
    }
  },
  watch: {
    selectedMark() {
      this.getCarModels()
    },
  },
  created() {
    this.getClients()
    this.getCarMarks()
  },
  methods: {
    async createApplication() {
      await this.$http.post('admin/applications/', this.form)
      await this.$router.push({ name: 'applications-list' })
    },
    async getClients() {
      const res = await this.$http.get('admin/users/?user_type=0')
      this.clients = res.data.results
    },
    async getCarMarks() {
      const res = await this.$http.get('admin/car-manufacturers/')
      this.carMarks = res.data.results
    },
    async getCarModels() {
      const res = await this.$http.get(
        `admin/car-models/?manufacturer=${this.selectedMark}`
      )
      this.carModels = res.data.results
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
